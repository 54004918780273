// import axios from '../../mixins/axiosInstanceV2';
// import qs from 'qs'
// import Compressor from 'compressorjs';
import axios from '../../mixins/axiosInstance'
import { io } from "socket.io-client";

const socket = io("https://crm.radiotrunk.com:5051", 
{
    transports: ['websocket','polling'],
    secure: true
});
socket.on('connection', () => {
    console.log('Connected')
});

const state = () => ({
    headers: [
        {text: "ID",sortable: true,value: "order_number"},
        {text:"Correo electronico",value:"contact_email"},
        {text:"Telefono",value:"phone"},
        {text: "Creado",sortable: true,value: "created_at"},
        {text: "Cerrado",sortable: true,value: "closed_at"},
        {text: "Costo de la venta",sortable:true,value: "total_line_items_price"},
        {text: "Estado del pago",sortable:true,value: "financial_status"}
    ],
    privilegeList: [
        { text: 'Disable text', value: 134217728},
        { text: 'Stun protection', value: 16777216 },
        { text: 'Management group', value: 8388608},
        { text: 'Dispatcher account', value: 4194304 },
        { text: 'Last group', value: 2097152},
        { text: 'Recieve S.O.S', value: 65536 },
        { text: 'Only listen', value: 32768 },
        { text: 'Video', value: 16384 },
        { text: 'View location', value: 4096 },
        { text: 'GPS Positioning', value: 2048},
        { text: 'Platform Audio', value: 1024},
        { text: 'Stun', value: 512 },
        { text: 'All call', value: 256 },
        { text: 'Display group members', value: 128 },
        { text: 'Monitor', value: 64},
        { text: 'Call logs', value: 16},
        { text: 'Do not disturb if private call', value: 8 },
        { text: 'Change group', value: 4 },
        { text: 'Private call', value: 2 },
        { text: 'Contact', value: 1 }
      ],
    dialogOrder:false,
    orders:[], //List of tickets deployed in the main table
    loading:true,
    search:"",
    TicketDialog: false, //Set if the Ticket's dialog is showed or not
    TicektInformation:{},
    ThumbnailData: [],
    selectedOrder:{}
})

const mutations={
    setOrders(state,value){
        state.orders=value
    },
    openOrderDialog(state){
        state.dialogOrder=true
    },
    closeOrderDialog(state){
        state.dialogOrder=false
    },
    setOrderInformation(state,value){
        state.selectedOrder=value
    },
    setOrderConfiguration(state,value){
        state.selectedOrder.orderInformation=value
    }
}

const actions={
    async getOrdersFromShopify({commit}){
        let orders=await axios.instance.get('/Shopify')
        await commit('setOrders',orders.data.data)
        await commit('setOrderInformation',orders.data.data[0])
        let orderinfo=await axios.instance.get('/orders/'+orders.data.data[0].note)
        await commit('setOrderConfiguration',orderinfo.data.data)
    },
    async openOrderDialog({commit},value){
        await commit('openOrderDialog')
        await commit('setOrderInformation',value)
        let orderinfo=await axios.instance.get('/orders/'+value.note)
        await commit('setOrderConfiguration',orderinfo.data.data)
    }
}

export default{
    namespaced:true,
    state,
    actions,
    mutations

}
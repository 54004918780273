import axios from '../../mixins/axiosInstanceV2.js'
import qs from 'qs'
const state = () => ({
    dropdownSpare:null,
    dropdownSpares:[],
    serialNumberSpare:null,
    sparesSelected:[],
    sparesOfService:[],
    dialogAddSpare:null,
    dialogEditSpare:null,
    spareName:'',
    sparePartNumber:'',
    spareID: ''
})
const getters = {}

const actions = {
    async getDropdownSpares({commit}){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        let res=await axios.instance.get("/spares");
        let Spares=[]
        if(res.data.data!="No registers detected")
        {
            for(let i=0;i<res.data.data.length;i++)
            {
                Spares.push({text:res.data.data[i].spare_name,value:{val:res.data.data[i].spare_id,show:res.data.data[i].spare_name}})
            }
        }
        commit('setDropdownSpares',Spares)
    },
    async getSpares({commit,rootState}){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        let res=await axios.instance.get("/spares?join=true&id="+rootState.Services.service_id);
        commit('setSparesSelected',res.data.data)
    },
    async addSpare({dispatch,rootState,state,commit}){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.post("/spares",qs.stringify({
            spare_sn:   state.serialNumberSpare,
            spare_id:   state.dropdownSpare.val,
            service_id: rootState.Services.service_id
        }));
        await dispatch('getSpares')
        await commit('updateDropdownSpare',null)
        await commit('updateSerialNumberSpare',null)
    },
    async addNewSpareToList({state,dispatch}){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.post("/spares",qs.stringify({
            spare_name:state.spareName.toUpperCase(),
            spare_part_number:state.sparePartNumber.toUpperCase()
        }));
        dispatch('getDropdownSpares')
        dispatch('closeAddSpareDialog')
    },
    async deleteSpare({dispatch},value){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.delete("/spares/"+value.ID+'?service=true');
        await dispatch('getSpares')
    },
    async getSparesOfService({commit},val){
        commit('updateSparesOfService',[]) 
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        let data = await axios.instance.get("/spares?join=true&id="+val.item.service_id)
        if(data.data.data==="No registers detected")
        {
            commit('updateSparesOfService',[]) 
        }else{
            commit('updateSparesOfService',data.data.data) 
        }
        
    },
    async updateSpare({state, dispatch}){
        try {
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            await axios.instance.put('/spares/'+state.spareID+'?service=true', qs.stringify({
                spare_sn: state.sparePartNumber
            }))
            await dispatch('getSpares')
            await dispatch('closeUpdateSpareDialog');
            await dispatch('Snackbars/setSnackbars',{text:'Una refacción se actualizó correctamente',type:'success'},{root:true})
        } catch (error) {
            if(error){
                await dispatch('Snackbars/setSnackbars',{text:'Ocurrió un error al actualizar la refacción',type:'error'},{root:true})
            }
        }

    },
    async openUpdateSpareDialog({commit}, value){
        console.log(value)
        commit('openUpdateSpareDialog')
        commit('updateSpareID', value.id)
        commit('updateSpareName',value.name)
        commit('updateSparePartNumber', value.SN)
    },
    async closeUpdateSpareDialog({commit}){
        commit('closeUpdateSpareDialog')
    },
    async closeAddSpareDialog({commit}){
        commit('closeAddSpareDialog')
    },
    async openAddSpareDialog({commit}){
        commit('openAddSpareDialog')
        commit('updateSpareName','')
        commit('updateSparePartNumber','')
    }
}
const mutations = {
    async updateSpareID(state, value){
        state.spareID=value
    },
    async updateSpareName(state,value){
        state.spareName=value
    },
    async updateSparePartNumber(state,value){
        state.sparePartNumber=value
    },
    async openAddSpareDialog(state){
        state.dialogAddSpare=true
    },
    async closeAddSpareDialog(state)
    {
        state.dialogAddSpare=false
    },
    async openUpdateSpareDialog(state){
        state.dialogEditSpare=true
    },
    async closeUpdateSpareDialog(state){
        state.dialogEditSpare=false
    },
    async updateDropdownSpare(state,value)
    {
        state.dropdownSpare=value
    },
    async updateSerialNumberSpare(state,value){
        state.serialNumberSpare=value
    },
    async updateDropdownSpares(state,value)
    {
        state.dropdownSpares=value
    },
    async setDropdownSpares(state,value)
    {
        state.dropdownSpares=value
    },
    async updateSparesOfService(state,value){
        state.sparesOfService=value
    },
    async setSparesSelected(state,value){
        if(value==='No registers detected')
        {  
            state.sparesSelected=[]
        
        }else{
            state.sparesSelected=value
        }
    },
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
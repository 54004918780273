import axios from '../../mixins/axiosInstanceV2.js';
import qs from 'qs';
const state=()=>({
    dropdownModels:[],
    dropdownModel:{},
    addModelDialog:null,
    dialogValuesModel:{
        name: "",
    },
})

const getters ={}

const actions ={
    async returnValuesModel({dispatch,commit},value){
        dispatch('getDropdownModels')
        commit('updateDropdownModel',value.dropdownModel)
    },
    async getDropdownModels({commit,rootState})
    {
        try{
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            let data = await axios.instance.get("/models?id="+rootState.Marks.dropdownMark.id);
            commit('updateDropdownModels',data.data.data)
        }catch(err)
        {
            commit('updateDropdownModels',[])
        }
    },
    async addModel({commit,state,rootState}){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.post("/models",qs.stringify({
            name:       state.dialogValuesModel.name.toUpperCase(),
            mark_id:    rootState.Marks.dropdownMark.id
        }));
        commit('closeAddModelDialog')
    },
}

const mutations ={
    async updateDropdownModel(state,value){
        state.dropdownModel=value
    },
    async updateDropdownModels(state,value){
        state.dropdownModels=value
    },
    async updateName(state,value){
        state.dialogValuesModel.name=value
    },
    async showAddModelDialog(state){
        state.addModelDialog=true
    },
    async closeAddModelDialog(state){
        state.addModelDialog=false
        state.dialogValuesModel.name=""
    }
}


export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
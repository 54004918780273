import axios from '../../mixins/axiosInstanceV2';
import axiosVersion from '../../mixins/axiosVersion'
const state=()=>({
    serviceReportDialog:{
        status:false,
        typeOfFile:"EXCEL",
    },
    dateInitReport:{
        date:'',
        dialog:false
    },
    dateEndReport:{
        date:'',
        dialog:false
    }
})

const getters ={}

const actions ={
    async closeServiceReportDialog({commit}){
        await commit('closeServiceReportDialog')
        await commit('cleanInputs')
    },
    async openServiceReportDialog({commit}){
        await commit('openServiceReportDialog')
        await commit('cleanInputs')
    },
    async cleanInputs({commit}){
        await commit('Clients/updateDropdownClient',[],{root:true})
        await commit('Devices/FetchDevices/updateDropdownDevice',[],{root:true})
        await commit('updateDateInitReport','')
        await commit('updateDateEndReport','')
    },
    async getReportByDevice({state,rootState,commit,dispatch}){
        try {
            var type
            if(state.serviceReportDialog.typeOfFile==='PDF')
            {
                type='pdf'
            }else
            {
                type='csv'
            }
            await commit('closeServiceReportDialog')
            await commit('Loader/showLoader',null,{root:true})
            const axiosConn = axiosVersion.instance('v3')
            axiosConn.defaults.headers.common['token'] = localStorage.getItem("token");
            var ReportByRadio= await axiosConn.post("/crm/devicesReport",{
                type:state.serviceReportDialog.typeOfFile,
                devices:rootState.Devices.FetchDevices.dropdownDevicesList,
                date_init:state.dateInitReport.date,
                date_end:state.dateEndReport.date,
            },{responseType: 'arraybuffer'});
            var blob = new File([ReportByRadio.data], {type:"application/"+type});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "reporte."+type
            link.click()
            await dispatch('Snackbars/setSnackbars',{text:'El reporte se descargo con exito',type:'success'},{root:true})
        } catch (error) {
            if(error=='Error: Request failed with status code 404')
            {
                await dispatch('Snackbars/setSnackbars',{text:'El dispositivo no tiene servicios',type:'warning'},{root:true})
            }else{
                await dispatch('Snackbars/setSnackbars',{text:'No se pudo descargar el reporte',type:'error'},{root:true})
            }
            await commit('openServiceReportDialog')
        }
       
        await commit('Loader/closeLoader',null,{root:true})
        
    },
    async getReportByClient({state,rootState,dispatch,commit}){
        try {
            var typefile
            if(state.serviceReportDialog.typeOfFile==='PDF')
            {
                typefile='pdf'
            }else
            {
                typefile='csv'
            }
            await commit('closeServiceReportDialog')
            await commit('Loader/showLoader',null,{root:true})  
            const axiosConn = axiosVersion.instance('v3')
            axiosConn.defaults.headers.common['token'] = localStorage.getItem("token");
            var Generalreport= await axiosConn.post("/clientsReport",{
                type:state.serviceReportDialog.typeOfFile,
                clients:rootState.Clients.dropdownClientsList,
                date_init:state.dateInitReport.date,
                date_end:state.dateEndReport.date,
            },{responseType: 'arraybuffer'});
            var blob = new File([Generalreport.data], {type:"application/"+typefile});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "reporte."+typefile
            link.click()
            await dispatch('Snackbars/setSnackbars',{text:'El reporte se descargo con exito',type:'success'},{root:true})
        } catch (error) {
            if(error=='Error: Request failed with status code 404')
            {
                await dispatch('Snackbars/setSnackbars',{text:'El dispositivo no tiene servicios',type:'warning'},{root:true})
            }else{
                console.log(error)
                await dispatch('Snackbars/setSnackbars',{text:'No se pudo descargar el reporte',type:'error'},{root:true})
            }
            await commit('openServiceReportDialog')
        }
            
        await commit('Loader/closeLoader',null,{root:true})

    },
    async getSparesReport({state,commit,dispatch}){
        try {
            var typefile
            if(state.serviceReportDialog.typeOfFile==='PDF')
            {
                typefile='pdf'
            }else
            {
                typefile='csv'
            }
            await commit('closeServiceReportDialog')
            await commit('Loader/showLoader',null,{root:true})  
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            var Generalreport= await axios.instance.get("/Report?date_init="+state.dateInitReport.date+"&date_end="+state.dateEndReport.date+"&type="+state.serviceReportDialog.typeOfFile,{responseType: 'arraybuffer'});
            var blob = new File([Generalreport.data], {type:"application/"+typefile});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "reporte."+typefile
            link.click()
            await dispatch('Snackbars/setSnackbars',{text:'El reporte se descargo con exito',type:'success'},{root:true})
        } catch (error) {
            if(error=='Error: Request failed with status code 404')
            {
                await dispatch('Snackbars/setSnackbars',{text:'No hay refacciones',type:'warning'},{root:true})
            }else{
                await dispatch('Snackbars/setSnackbars',{text:'No se pudo descargar el reporte',type:'error'},{root:true})
            }
            await commit('openServiceReportDialog')
        }
            
        await commit('Loader/closeLoader',null,{root:true})
    }
}

const mutations ={
    openSpareReportDialog(state){
        state.spareReportDialog.status=true
    },
    openServiceReportDialog(state){
        state.serviceReportDialog.status=true
    },
    closeServiceReportDialog(state){
        state.serviceReportDialog.status=false
    },
    updateTypeOfFile(state,value){
        state.serviceReportDialog.typeOfFile=value
    },
    updateDateInitReport(state,value){
        state.dateInitReport.date=value
    },
    updateDateEndReport(state,value){
        state.dateEndReport.date=value
    }
}


export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
/*import axios from '../../mixins/axiosInstance'
import qs from 'qs';*/

const state = () => ({
    returnImages:[]
})
const getters = {}

const actions = {
   
}
const mutations = {
    async updateReturnImages(state,value)
    {
        state.returnImages=value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
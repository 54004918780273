import axios from '../../../mixins/axiosInstanceV2.js'

const state=()=>({
    search: '',
    loading: true,
    devices: [],
    dataLength: 1,
    dropdownDevice:null,
    dropdownDevices:[],
    dropdownDevicesList:[],
    page:1,
    headers: [
        {
            text: "ID", sortable: true, value: "device_id",divider:true
        },
        {
            text: "Dispositivo", sortable: true, value: "type",divider:true
        },
        
        {
            text: "Numero de serie", sortable: true, value: "SN",divider:true
        },
        {
            text: "IMEI", sortable: true, value: "IMEI", divider:true
        },
        {
            text: "Empresa", sortable: true, value: "company", divider:true
        },
        {
            text: "Cuenta", sortable: true, value: "account",divider:true
        },
        {
            text: "Sucursal", sortable: true, value: "dev_username",divider:true
        },
        {
            text: "Inicio Cuenta", sortable: true, value: "device_date_init",divider:true
        },
        {
            text: "Fin Cuenta", sortable: true, value: "device_date_end" , divider:true
        },
        {
            text: "Usuario Externo", sortable: true, value: "externalUsers", divider:true
        },
        {
            text: "Carrier", sortable: true, value: "carrier",divider:true
        },
        {
            text: "Numero de contrato", sortable: true, value: "contract_number",divider:true
        },
        {
            text: "Numero de telefono", sortable: true, value: "phone_number",divider:true
        },
        {
            text: "Cuenta hija", sortable: true, value: "child_account",divider:true
        },
        {
            text: "Cuenta padre", sortable: true, value: "parent_account",divider:true
        },
        {
            text: "Numero de SIM", sortable: true, value: "sim_number",divider:true
        },
        {
            text: "Inicio SIM", sortable: true, value: "sim_date_init",divider:true
        },
        {
            text: "Fin SIM", sortable: true, value: "sim_date_end",divider:true
        },
        {
            text: "Acciones", sortable: false, value: "actions",divider:true
        },
    ]
})

const getters ={}

const actions ={
    async getAllDevices({ commit,state,dispatch },value){
        
        if(value){
            await commit('setPageStart',value.pageStart)
            await commit('setItemsPerPage',value.itemsPerPage)
            await commit('setPage',value.page)
        }
        if(!state.search)
        {
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            let data = await axios.instance.get("/device?join=true&start="+state.pageStart+"&stop="+state.itemsPerPage);
            if(data.data.data != "No registers detected"){
                await commit('setItemsLength', data.data.length)
                await commit('setDevices', data.data.data)
            }
            else{
                commit('reloadItemState', false)
            }
        }else{
            dispatch('getSearchedTermData',state.search)
        }
        
    },
    async getDropdownDevices({commit}){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        var radio= await axios.instance.get("/device");
        commit('updateDropdownDevices',radio.data.data)
    },
    async getSearchedTermData({commit, state,dispatch},value){
        commit('setSearchterm',value)
        if(value){
            let data;
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            data = await axios.instance.get("/device?join=true&search=true", { params: { term: state.search,start:state.pageStart,stop:state.itemsPerPage } });
            if(data.data.data != "No registers detected"){
                await commit('setDevices', data.data.data)
                await commit('setItemsLength', data.data.length)
            }
            else{
                commit('reloadItemState', false)
            }
            if(state.dataLength<(state.page*state.itemsPerPage)){
                commit('setPage',1)
                commit('setPageStart',0)
            }
        }else{
            dispatch('getAllDevices',{pageStart:0,itemsPerPage:10,page:1})
        }
    }
}

const mutations ={
    async setPage(state,value){
        state.page=value
    },
    async setPageStart(state,value){
        state.pageStart=value
    },
    async setItemsPerPage(state,value){
        state.itemsPerPage=value
    },
    async setItemsLength(state,value){
        state.dataLength=value
    },
    updateDropdownDevices(state,value){
        state.dropdownDevices=value
    },
    updateDropdownDevice(state,value){
        if(value.length){
            state.dropdownDevicesList=value.map((v)=>{
                return {device_id:v}
            })
        }
        state.dropdownDevice=value
    },
    setSearchterm(state, payload){
        state.search = payload
    },
    async setDevices(state, value){
        state.devices = value
        state.loading = false
    },
    removeActionsHeader(state){
        state.headers=state.headers.filter(function(value){
            return value.text !== 'Acciones'
        })
    },
    setActionsHeader(state){
        state.headers.push({text: "Acciones", sortable: false, value: "actions",divider:true})
    }
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
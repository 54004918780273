import axios from '../../../mixins/axiosInstanceV2.js'
import qs from 'qs'
const state=()=>({
    dialogEditMultipleStatus:false,
    dialogStatus:false,
    dialogValuesDevices:{
        deviceID:null,
        device:null,
        serialNumber:null,
        IMEI:null,
        username:"",
        account:"",
        dateInitAccount:null,
        accountDateDialog:null,
    },
})

const getters ={}

const actions ={
    async showUpdateDeviceDialog({commit,dispatch},value)
    {
        commit('showUpdateDeviceDialog')
        dispatch('Marks/getDropdownMarks',undefined,{root:true})
        commit('Marks/updateDropdownMark',{id:value.mark_id,name:value.name},{root:true})
        dispatch('Models/getDropdownModels',undefined,{root:true}) 
        commit('Models/updateDropdownModel',{id:value.model_id,name:value.model_name},{root:true})

        commit('updateDeviceID',value.device_id) 
        commit('updateDevice',value.type) 
        commit('updateSN',value.SN)
        commit('updateIMEI',value.IMEI)
        commit('updateUsername',value.dev_username)
        commit('updateAccount',value.account)
        commit('updateDateInitAccount',value.device_date_init.substr(0,10))

        commit('Sims/UpdateSims/updateSimID',value.sim_id,{root:true})
        commit('Sims/UpdateSims/updateCarrier',value.carrier,{root:true})
        commit('Sims/UpdateSims/updatePhoneNumber',value.phone_number,{root:true})
        commit('Sims/UpdateSims/updateSimNumber',value.sim_number,{root:true})
        commit('Sims/UpdateSims/updateParentAccount',value.parent_account,{root:true})
        commit('Sims/UpdateSims/updateChildAccount',value.child_account,{root:true})
        commit('Sims/UpdateSims/updateContractNumber',value.contract_number,{root:true})
        commit('Sims/UpdateSims/updateDateInitSim',value.sim_date_init.substr(0,10),{root:true})
        commit('Sims/UpdateSims/updateDateEndSim',value.sim_date_end.substr(0,10),{root:true})
        dispatch('Clients/getDropdownClients',undefined,{root:true})
        commit('Clients/updateDropdownClient',{id:value.client_id,name:value.company},{root:true})
        dispatch('ExternalUsers/getDropdownExternalUsers',undefined,{root:true})
        commit('ExternalUsers/updateChipsExternalUsers',value.externalUsers,{root:true})
    },
    async UpdateDeviceInformation({commit,state,rootState,dispatch})
    {
        try {
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            await axios.instance.patch("/device/"+state.dialogValuesDevices.deviceID,qs.stringify({
                account:        state.dialogValuesDevices.account,
                username:       state.dialogValuesDevices.username,
                date_init:      state.dialogValuesDevices.dateInitAccount,
                type:           state.dialogValuesDevices.device,
                model_id:       rootState.Models.dropdownModel.id,
                IMEI:           state.dialogValuesDevices.IMEI,
                SN:             state.dialogValuesDevices.serialNumber,
                client_id:      rootState.Clients.dropdownClient.id

            }));
            await dispatch('Sims/UpdateSims/updateSim',null,{root:true})
            await dispatch('Devices/FetchDevices/getAllDevices',null,{root:true})
            // await dispatch('notifications/getAllNotifications', null, {root:true})
            // await dispatch('notifications/getCount', null, {root: true})
            await commit('closeUpdateDeviceDialog')
        } catch (error) {
            console.log(error)
        }
        
    },
    async UpdateDeviceInformationMultiple({commit,state,rootState,dispatch})
    {
        try {
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            await axios.instance.patch("/device/"+state.dialogValuesDevices.deviceID,qs.stringify({
                account:        state.dialogValuesDevices.account,
                username:       state.dialogValuesDevices.username,
                date_init:      state.dialogValuesDevices.dateInitAccount,
                type:           state.dialogValuesDevices.device,
                model_id:       rootState.Models.dropdownModel.id,
                IMEI:           state.dialogValuesDevices.IMEI,
                SN:             state.dialogValuesDevices.serialNumber,
                client_id:      rootState.Clients.dropdownClient.id

            }));
            await dispatch('Sims/UpdateSims/updateSim',null,{root:true})
            await commit('closeUpdateDeviceDialog')
        } catch (error) {
            console.log(error)
        }
        
    },
    async showUpdateMultipleDevicesDialog({commit}){
        await commit('showUpdateMultipleDevicesDialog')
        await commit('updateDateInitAccount',undefined)
        await commit('Sims/UpdateSims/updateDateInitSim',undefined,{root:true})
        await commit('Sims/UpdateSims/updateDateEndSim',undefined,{root:true})
        await commit('Clients/updateDropdownClient',undefined,{root:true})
        await commit('ExternalUsers/updateDropdownExtUsr',[],{root:true})
    },
    async updateMultipleDevices({rootState,state,commit,dispatch})
    {
        let flags=[false,false,false,false]
        let err=[undefined,null,'null','undefined']
        if(!err.includes(state.dialogValuesDevices.dateInitAccount))
        {
            flags[0]=true
        }
        if(!err.includes(rootState.Sims.UpdateSims.dialogValuesSim.dateInitSim))
        {
            flags[1]=true
        }
        if(!err.includes(rootState.Sims.UpdateSims.dialogValuesSim.dateEndSim))
        {
            flags[2]=true
        }
        if(!err.includes(rootState.Clients.dropdownClient))
        {
            flags[3]=true
        }
        await commit('Loader/showLoader',null,{root:true})
        for(let i=0;i<rootState.Devices.selectedItems.length;i++)
        {
            commit('closeUpdateMultipleDevicesDialog')
            try {
                await commit('Marks/updateDropdownMark',{id:rootState.Devices.selectedItems[i].mark_id,name:rootState.Devices.selectedItems[i].name},{root:true})
                await commit('Models/updateDropdownModel',{id:rootState.Devices.selectedItems[i].model_id,name:rootState.Devices.selectedItems[i].model_name},{root:true})
                await commit('updateDeviceID',rootState.Devices.selectedItems[i].device_id) 
                await commit('updateDevice',rootState.Devices.selectedItems[i].type) 
                await commit('updateSN',rootState.Devices.selectedItems[i].SN)
                await commit('updateIMEI',rootState.Devices.selectedItems[i].IMEI)
                await commit('updateUsername',rootState.Devices.selectedItems[i].dev_username)
                await commit('updateAccount',rootState.Devices.selectedItems[i].account) 
                await commit('Sims/UpdateSims/updateSimID',rootState.Devices.selectedItems[i].sim_id,{root:true})
                await commit('Sims/UpdateSims/updateCarrier',rootState.Devices.selectedItems[i].carrier,{root:true})
                await commit('Sims/UpdateSims/updatePhoneNumber',rootState.Devices.selectedItems[i].phone_number,{root:true})
                await commit('Sims/UpdateSims/updateSimNumber',rootState.Devices.selectedItems[i].sim_number,{root:true})
                await commit('Sims/UpdateSims/updateParentAccount',rootState.Devices.selectedItems[i].parent_account,{root:true})
                await commit('Sims/UpdateSims/updateChildAccount',rootState.Devices.selectedItems[i].child_account,{root:true})
                await commit('Sims/UpdateSims/updateContractNumber',rootState.Devices.selectedItems[i].contract_number,{root:true})


                if(flags[0]===false)
                {
                    await commit('updateDateInitAccount',rootState.Devices.selectedItems[i].device_date_init.substr(0,10))
                }
                if(flags[1]===false)
                {
                    await commit('Sims/UpdateSims/updateDateInitSim',rootState.Devices.selectedItems[i].sim_date_init.substr(0,10),{root:true})
                }
                if(flags[2]===false)
                {
                    await commit('Sims/UpdateSims/updateDateEndSim',rootState.Devices.selectedItems[i].sim_date_end.substr(0,10),{root:true})
                }
                if(flags[3]===false)
                {
                    await commit('Clients/updateDropdownClient',{id:rootState.Devices.selectedItems[i].client_id,name:rootState.Devices.selectedItems[i].company},{root:true})
                }
                await dispatch('ExternalUsers/addExternalUserListToMultipleDevices',null,{root:true})
                await dispatch('UpdateDeviceInformationMultiple')
                
                
            } catch (error) {
                await dispatch('Snackbars/setSnackbars',{text:'Algo salio mal al actualizar un dispositivo',type:'error'},{root:true})
            }
        }
        await dispatch('Devices/FetchDevices/getAllDevices',null,{root:true})
            // await dispatch('notifications/getAllNotifications', null, {root:true})
            // await dispatch('notifications/getCount', null, {root: true})
        await commit('Loader/closeLoader',null,{root:true})
        await dispatch('Snackbars/setSnackbars',{text:'Dispositivos actualizados',type:'success'},{root:true})
        await dispatch('closeUpdateMultipleDevicesDialog')
                
    },
    async closeUpdateMultipleDevicesDialog({commit})
    {
        await commit('updateDateInitAccount',undefined)
        await commit('Sims/UpdateSims/updateDateInitSim',undefined,{root:true})
        await commit('Sims/UpdateSims/updateDateEndSim',undefined,{root:true})
        await commit('Clients/updateDropdownClient',undefined,{root:true})
    }
}

const mutations ={
    updateDeviceID(state,value){
        state.dialogValuesDevices.deviceID=value
    },
    updateDevice(state,value){
        state.dialogValuesDevices.device=value
    },
    updateSN(state,value)
    {
        state.dialogValuesDevices.serialNumber=value
    },
    updateIMEI(state,value)
    {
        state.dialogValuesDevices.IMEI=value
    },
    updateUsername(state,value)
    {
        state.dialogValuesDevices.username=value
    },
    updateAccount(state,value){
        state.dialogValuesDevices.account=value
    },
    updateDateInitAccount(state,value){
        state.dialogValuesDevices.dateInitAccount=value
    },
    showUpdateDeviceDialog(state){
        state.dialogStatus = true
    },
    closeUpdateDeviceDialog(state){
        state.dialogStatus = false
    },
    showUpdateMultipleDevicesDialog(state){
        state.dialogEditMultipleStatus = true
    },
    closeUpdateMultipleDevicesDialog(state){
        state.dialogEditMultipleStatus=false
    }
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
import AddSims from "./AddSims";
import DeleteSims from "./DeleteSims";
import UpdateSims from "./UpdateSims";
import FetchSims from "./FetchSims";

const state = () => ({
  carriers: [
    { text: "Telcel", value: "Telcel" },
    { text: "Altan", value: "Altan" },
    { text: "ATT", value: "ATT" },
    { text: "Multicarrier", value: "Multicarrier" },
    { text: "FreedomPop", value: "FreedomPop" },
  ],
});

const getters = {};

const actions = {};

const mutations = {};

const modules = {
  AddSims,
  DeleteSims,
  UpdateSims,
  FetchSims,
};
export default {
  namespaced: true,
  state,
  modules,
  getters,
  actions,
  mutations,
};

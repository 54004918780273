import axios from '../../../mixins/axiosInstanceV2.js';
import qs from 'qs';
const state=()=>({
    dialogStatus:false,
    statusToClose:null,
    menuCuenta: false,
    menuSim: false,
    dialogToClose:{
        status:null
    },
    addDeviceHeaders:['','-','ID','Dispositivo','N.Serie','IMEI','Marca','Modelo','Usuario','Cuenta','Fecha Inicio Cuenta','Carrier','Telefono','N° SIM','Cuenta Padre','Cuenta Hija','Contrato','Fecha Inicio SIM','Fecha Fin SIM','Cliente','Usuario Externo'],
    dialogValuesDevices:{
        device:null,
        serialNumber:null,
        IMEI:null,
        username:"",
        account:"",
        dateInitAccount:null,
        accountDateDialog:null,
    },
    listOfDevicesToAdd:[],
})

const getters ={}

const actions ={
    async editToList({commit,state,dispatch},value){
        commit('returnValuesDevice',value)
        commit('Sims/AddSims/returnValuesSim',{
            carrier:state.listOfDevicesToAdd[value].carrier,
            phoneNumber:state.listOfDevicesToAdd[value].phoneNumber,
            simNumber:state.listOfDevicesToAdd[value].simNumber,
            dateInitSim:state.listOfDevicesToAdd[value].dateInitSim,
            dateEndSim:state.listOfDevicesToAdd[value].dateEndSim,
            parentAccount:state.listOfDevicesToAdd[value].parentAccount,
            childAccount:state.listOfDevicesToAdd[value].childAccount,
            contractNumber:state.listOfDevicesToAdd[value].contractNumber,
        },{ root: true })
        dispatch('Marks/returnValuesMark',{dropdownMark:state.listOfDevicesToAdd[value].dropdownMark},{ root: true })
        dispatch('Models/returnValuesModel',{dropdownModel:state.listOfDevicesToAdd[value].dropdownModel},{ root: true })
        dispatch('ExternalUsers/returnValuesExternalUser',{dropdownExternalUser:state.listOfDevicesToAdd[value].dropdownExternalUser},{ root: true })
        dispatch('Clients/returnValuesClient',{dropdownClient:state.listOfDevicesToAdd[value].dropdownClient},{ root: true })
        commit('deleteToList',value)
    },
    async addToList({rootState,state,commit}){

        let err=[undefined,'',null,' ']
        let obj={
            device:                 state.dialogValuesDevices.device,
            SN:                     state.dialogValuesDevices.serialNumber,
            IMEI:                   state.dialogValuesDevices.IMEI,
            username:               state.dialogValuesDevices.username,
            account:                state.dialogValuesDevices.account,
            dateInitAccount:        state.dialogValuesDevices.dateInitAccount,
            dropdownMark:           rootState.Marks.dropdownMark,
            dropdownModel:          rootState.Models.dropdownModel,
            carrier:                rootState.Sims.AddSims.dialogValuesSim.carrier,
            phoneNumber:            rootState.Sims.AddSims.dialogValuesSim.phoneNumber,
            simNumber:              rootState.Sims.AddSims.dialogValuesSim.simNumber,
            dateInitSim:            rootState.Sims.AddSims.dialogValuesSim.dateInitSim,
            dateEndSim:             rootState.Sims.AddSims.dialogValuesSim.dateEndSim,
            parentAccount:          rootState.Sims.AddSims.dialogValuesSim.parentAccount,
            childAccount:           rootState.Sims.AddSims.dialogValuesSim.childAccount,
            contractNumber:         rootState.Sims.AddSims.dialogValuesSim.contractNumber,
            dropdownExternalUser:   rootState.ExternalUsers.dropdownExternalUser,
            dropdownClient:         rootState.Clients.dropdownClient,
        }
        if( !err.includes(obj.device)&&
            !err.includes(obj.SN)&&
            !err.includes(obj.username)&&
            !err.includes(obj.account)&&
            !err.includes(obj.dateInitAccount)&&
            !err.includes(obj.dropdownMark)&&
            !err.includes(obj.dropdownModel)&&
            !err.includes(obj.carrier)&&
            !err.includes(obj.phoneNumber)&&
            !err.includes(obj.simNumber)&&
            !err.includes(obj.dateInitSim)&&
            !err.includes(obj.dateEndSim)&&
            !err.includes(obj.dropdownExternalUser)&&
            !err.includes(obj.dropdownClient))
        {
            commit('updateListOfDevicesToAdd',obj)
            commit('resetDialogValuesDevices')
            commit('Models/updateDropdownModel', undefined, { root: true })
            commit('Marks/updateDropdownMark', undefined, { root: true })
            commit('ExternalUsers/updateDropdownExtUsr', [], { root: true })
            commit('Clients/updateDropdownClient', undefined, { root: true })
            commit('Sims/AddSims/resetDialogValuesSims', null, { root: true }) 
        }else{
            alert('LLene los campos necesarios')
        }
         
    },
    async closeDialog({commit,state},value)
    {
        if(state.listOfDevicesToAdd.length>0)
        {
            switch (value)
            {
                
                case 1:
                    commit('toCloseDialog')
                    break;
                case 2:
                    commit('toCloseDialog')
                    commit('closeAddDeviceDialog')
                    commit('resetDialogValuesDevices')
                    commit('Models/updateDropdownModel', undefined, { root: true })
                    commit('Marks/updateDropdownMark', undefined, { root: true })
                    commit('ExternalUsers/updateDropdownExtUsr', [], { root: true })
                    commit('Clients/updateDropdownClient', undefined, { root: true })
                    commit('Sims/AddSims/resetDialogValuesSims', null, { root: true })  
                    commit('resetList')
                    break;
            }
        }else{
            commit('closeAddDeviceDialog')
            commit('resetDialogValuesDevices')
            commit('Models/updateDropdownModel', undefined, { root: true })
            commit('Marks/updateDropdownMark', undefined, { root: true })
            commit('ExternalUsers/updateDropdownExtUsr', [], { root: true })
            commit('Clients/updateDropdownClient', undefined, { root: true })
            commit('Sims/AddSims/resetDialogValuesSims', null, { root: true })  
            commit('resetList')
        }
    },
    async sendUsers({dispatch,commit,state,rootState}){
        const i=state.listOfDevicesToAdd.length-1
        await commit('Loader/showLoader',null,{root:true})
        for(let j=i;j>=0;j--)
        {
            commit('closeAddDeviceDialog')
            try {
                await dispatch('Sims/AddSims/addSim',{
                    carrier:                state.listOfDevicesToAdd[j].carrier,
                    phoneNumber:            state.listOfDevicesToAdd[j].phoneNumber,
                    simNumber:              state.listOfDevicesToAdd[j].simNumber,
                    dateInitSim:            state.listOfDevicesToAdd[j].dateInitSim,
                    dateEndSim:             state.listOfDevicesToAdd[j].dateEndSim,
                    parentAccount:          state.listOfDevicesToAdd[j].parentAccount,
                    childAccount:           state.listOfDevicesToAdd[j].childAccount,
                    contractNumber:         state.listOfDevicesToAdd[j].contractNumber,
                },{root:true})
                axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
                let device=await axios.instance.post("/device",qs.stringify({
                    model_id:   state.listOfDevicesToAdd[j].dropdownModel.id,
                    type:       state.listOfDevicesToAdd[j].device,
                    SN:         state.listOfDevicesToAdd[j].SN,
                    account:    state.listOfDevicesToAdd[j].account,
                    username:   state.listOfDevicesToAdd[j].username,
                    IMEI:       state.listOfDevicesToAdd[j].IMEI,
                    sim_id:     rootState.Sims.AddSims.lastSimAdded,
                    client_id:  state.listOfDevicesToAdd[j].dropdownClient.id,
                    date_init:  state.listOfDevicesToAdd[j].dateInitAccount,
                }));
                await commit('ExternalUsers/updateDropdownExtUsr', state.listOfDevicesToAdd[j].dropdownExternalUser, { root: true })
                await dispatch('ExternalUsers/addExternalUserToNewDevice',device.data.data.device_id,{root:true})
                
                await commit('Sims/AddSims/updateLastSimAdded',undefined,{root:true})
                await commit('deleteToList',j)
                await dispatch('Snackbars/setSnackbars',{text:'Un dispositivo se agrego correctamente',type:'success'},{root:true})
            } catch (error) {
                if(error)
                {
                    await dispatch('Snackbars/setSnackbars',{text:'Un dispositivo no se agrego correctamente',type:'error'},{root:true})
                }
            }
        }
        await commit('Loader/closeLoader',null,{root:true})
        if(state.listOfDevicesToAdd.length===0)
        {

            dispatch('closeDialog',2)
        }else{
            commit('showAddDeviceDialog')
            await dispatch('Snackbars/setSnackbars',{text:'Un dispositivo no se agrego',type:'error'},{root:true})
        }
        await dispatch('Devices/FetchDevices/getAllDevices',null,{root:true})
        

    }

}

const mutations ={
    /*Start mutations to change the state*/
    updateDevice(state,value){
        state.dialogValuesDevices.device=value
    },
    updateSN(state,value)
    {
        state.dialogValuesDevices.serialNumber=value
    },
    updateIMEI(state,value)
    {
        state.dialogValuesDevices.IMEI=value
    },
    updateUsername(state,value)
    {
        state.dialogValuesDevices.username=value
    },
    updateAccount(state,value){
        state.dialogValuesDevices.account=value
    },
    updateDateInitAccount(state,value){
        state.dialogValuesDevices.dateInitAccount=value
    },
    /*End mutations to change the state*/
    resetDialogValuesDevices(state)
    {
        state.dialogValuesDevices.device=undefined
        state.dialogValuesDevices.serialNumber=null
        state.dialogValuesDevices.IMEI=null
        state.dialogValuesDevices.username=null
        state.dialogValuesDevices.account=null
        state.dialogValuesDevices.dateInitAccount=null
        state.dialogValuesDevices.accountDateDialog=null
    },
    updateListOfDevicesToAdd(state,value)
    {
        state.listOfDevicesToAdd.push(value)
    },
    showAddDeviceDialog(state){
        state.dialogStatus = true
    },
    closeAddDeviceDialog(state){
        state.dialogStatus = false
    },
    toCloseDialog(state)
    {
        state.statusToClose=!state.statusToClose
    },
    deleteToList(state,value){
        state.listOfDevicesToAdd.splice(value,1)
    },
    returnValuesDevice(state,value)
    {
        state.dialogValuesDevices.device=state.listOfDevicesToAdd[value].device
        state.dialogValuesDevices.serialNumber=state.listOfDevicesToAdd[value].SN
        state.dialogValuesDevices.IMEI=state.listOfDevicesToAdd[value].IMEI
        state.dialogValuesDevices.username=state.listOfDevicesToAdd[value].username
        state.dialogValuesDevices.account=state.listOfDevicesToAdd[value].account
        state.dialogValuesDevices.dateInitAccount=state.listOfDevicesToAdd[value].dateInitAccount
    },
    resetList(state)
    {
        state.listOfDevicesToAdd=[]
    }
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
import axios from '../../../mixins/axiosInstanceV2.js'
const state=()=>({
    dialogStatus:false,
    dialogDeleteMultipleStatus:false,
    dialogValuesDevices:{
        deviceID:null,
        serialNumber:null,
        IMEI:null,
    },
})

const getters ={}

const actions ={
    async showDeleteDeviceDialog({commit},value)
    {
        await commit('showDeleteDeviceDialog')
        await commit('updateDeviceID',value.device_id)
        await commit('updateDeviceSN',value.SN)
        await commit('updateDeviceIMEI',value.IMEI)
    },
    async showDeleteMultipleDevicesDialog({commit}){
        commit('showDeleteMultipleDevicesDialog')
    },
    async deleteDevice({commit,dispatch,state})
    {
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.delete("/device/"+state.dialogValuesDevices.deviceID);
        await dispatch('Devices/FetchDevices/getAllDevices',{pageStart:0,itemsPerPage:10,page:1},{root:true})
        await commit('closeDeleteDeviceDialog')
    },
    async deleteMultipleDevices({dispatch,commit,rootState}){
        for(let i=0; i<rootState.Devices.selectedItems.length; i++){
            await commit('updateDeviceID',rootState.Devices.selectedItems[i].device_id)
            await dispatch('deleteDevice')
        }
        await commit('closeDeleteMultipleDevicesDialog')
        await commit('Devices/updateSelectedItems',[],{root:true})
    }
    
}

const mutations ={
    showDeleteDeviceDialog(state){
        state.dialogStatus=true
    },
    showDeleteMultipleDevicesDialog(state)
    {
        state.dialogDeleteMultipleStatus=true
    },
    closeDeleteDeviceDialog(state){
        state.dialogStatus=false
    },
    closeDeleteMultipleDevicesDialog(state){
        state.dialogDeleteMultipleStatus=false
    },
    updateDeviceID(state,value){
        state.dialogValuesDevices.deviceID=value
    },
    updateDeviceSN(state,value){
        state.dialogValuesDevices.serialNumber=value
    },
    updateDeviceIMEI(state,value){
        state.dialogValuesDevices.IMEI=value
    }
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
import axios from '../../mixins/axiosInstance.js';
import qs from 'qs';

const state = () => ({
    dialog: false,
    dateStart: "",
    date: "",
    account_id: "",
})

const getters = {}

const actions = {
    async setUpdatedDate({state, commit}){
        if(state.date === "" || state.date === undefined){
            alert('Selecciona una fecha');
        }
        else{
            axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
            var response = await axios.instance.patch("/accounts/"+state.account_id,qs.stringify({
                init_date:state.date
            }));
            console.log(response);
            commit('closeDialog');
        }
    },
}

const mutations = {
    showDialog(state, account_id){
        state.dialog = true
        state.account_id = account_id
    },
    closeDialog(state){
        state.dialog = false
        state.date = ""
        state.account_id = ""
    },
    setDatePicker(state, date){
        state.date = date;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import axios from '../../mixins/axiosInstanceV2.js'
import qs from 'qs'
const state = () => ({
   dialogAddSpare:{
       status:null
   },
   dialogEditSpare:{
        status:null
   },
   values:{
       name:""
   }
})

const getters = {}

const actions = {
   async addSpare({commit,state})
   {
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.post("/spares",qs.stringify({
            spare_name:state.values.name.toUpperCase()
        }));
        commit('closeAddSpare')
        commit('resetValues')
   }
}

const mutations = {
    ShowAddSpare(state){
        state.dialogAddSpare.status=true
    },
    closeAddSpare(state)
    {
        state.dialogAddSpare.status=false
        this.resetValues;
    },
    resetValues(state)
    {
        state.values={
            name:""
        }
    },
    updateValues(state,val)
    {
        switch(val.id)
        {
            case 1:
                state.values.name=val.value;
            break;
        }
    }
}

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import axios from '../../mixins/axiosInstance.js';
const state = () => ({

    tempItem: [],
    search: '',
    singleExpand: true,
    loading: true,
    logsTable: [],
    headers: [
        {
            text: "ID",
            sortable: true,
            value: "id",
        },
        {
            text: "Usuario",
            sortable: true,
            value: "nombre",
        },
        {
            text: "Movimiento",
            sortable: true,
            value: "accion",
        },
        {
            text: "Contenido",
            sortable: false,
            value: "movimiento",
        },
        {
            text: "Fecha",
            sortable: true,
            value: "fecha",
        }
    ],
})

const getters = {}

const actions = {
    async loadItems ({ commit }) {
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        var response= await axios.instance.post("/logs")
        if(response.status===204)
          {
              commit('changeLoading')
          }
        commit('setLogs',response.data.data)
        
        
    }
    
}

const mutations = {
    setLogs(state,logs){
        state.logsTable=logs
        state.loading=false
    },
    changeLoading(state){
        state.loading=false
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
const state=()=>({
    snackbars: [],
    currentSnackbar:{
        color: "",
        icon: "",
        mode: "multi-line",
        position: "top",
        title: "",
        visible: false,
        text: "",
        timeout: -1
    }
})

const getters ={}

const actions ={
    async setSnackbars({commit}, value){
        commit('purgeSnack')
        let obj={
            color: "",
            icon: "",
            mode: "multi-line",
            position: "top",
            title: "",
            visible: true,
            text: "",
            timeout: -1
        }
        switch(value.type){
            case "error":
                obj.color = "error"
                obj.icon = "mdi-alert-octagon"
                obj.title = "Error"
                
            break;
            case "warning":
                obj.color = "warning"
                obj.icon = "mdi-alert"
                obj.title = "Advertencia"
            break;
            case "success":
                obj.color = "success"
                obj.icon = "mdi-check-circle"
                obj.title = "Hecho!"
            break;
            case "info":
                obj.color = "info"
                obj.icon = "mdi-information"
                obj.title = "Información"
            break;
        }
        obj.text = value.text
        commit('pushSnackbars', obj)
    },
    async visible({commit}){
        commit('visible')
        commit('purgeSnack')
    }
}

const mutations ={
      async visible(state){
        state.snackbars.pop()
        if(state.snackbars.length > 0){
            state.currentSnackbar = state.snackbars[state.snackbars.length-1]
        }
        else{
            state.currentSnackbar.visible = false
        }
      },
      async purgeSnack(state){
        state.snackbars = state.snackbars.filter(function(value){
          return value.visible === true
        })
      },
      async pushSnackbars(state, value){
          state.snackbars.push(value)
          state.currentSnackbar = state.snackbars[state.snackbars.length-1]
      },
      async resetSnackbars(state){
        state.snackbars = []
      }
}


export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
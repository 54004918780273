import AddDevices from './AddDevices'
import DeleteDevices from './DeleteDevices'
import FetchDevices from './FetchDevices'
import UpdateDevices from './UpdateDevices'
const state=()=>({
    typeOfDevices:[{text:'Radio',value:'Radio'},{text:'Router',value:'Router'},{text:'BodyCam',value:'BodyCam'}],
    selectedItems:[],
})

const getters ={}

const actions ={
    updateSelectedItems({commit},value)
    {
        commit('updateSelectedItems',value)
        if(value.length==0)
        {
            commit('Devices/FetchDevices/setActionsHeader',null,{root:true})
        }else{
            commit('Devices/FetchDevices/removeActionsHeader',null,{root:true})
        }
    }
}

const mutations ={
    updateSelectedItems(state,value){
        state.selectedItems=value
    }
}

const modules={
    AddDevices,
    DeleteDevices,
    FetchDevices,
    UpdateDevices
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules
}
import axios from '../../mixins/axiosInstance'
import qs from 'qs';
const state = () => ({
    URI: '/login',
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /\S+@radiotrunk.com+/.test(v) || 'E-mail must be valid',
    ],
    password: '',
    isVisiblePassword: false,
    passwordRules: [
      v => !!v || 'password is required',
    ],
    containerStyle: '',
})

const getters = {}

const actions = {
    async login({ state,dispatch }){
        axios.instance.post(state.URI, qs.stringify({
          email: state.email,
          password: state.password
        })).then(function(response){
          localStorage.setItem('token', response.data.data.token)
          localStorage.setItem('isAdmin', response.data.data.isAdmin)
          localStorage.setItem('email', response.data.data.email)
          localStorage.setItem('name', response.data.data.name)
          window.location.reload()
        }).catch(function(error){
          if(error){
            dispatch('Snackbars/setSnackbars', {
              type:'error',
              text: error.response.data.error,
            }, { root: true })
          }
          else{
            dispatch('Snackbars/setSnackbars', {
              type:'error',
              text: 'Other error has ocurred',
            }, { root: true })
          }
        })
    },
}

const mutations = {
  
  updatePassword(state,value){
    state.password=value
  },
  updateEmail(state,value){
    state.email=value
  },
  updateIsVisiblePassword(state){
    state.isVisiblePassword=!state.isVisiblePassword
  },
  reziseBackground(state){
    let padding_top
    if(window.innerHeight<900)
    {
      padding_top=window.innerHeight/4
    }else{
      padding_top=window.innerHeight/2.5
    }
    state.containerStyle = 'padding-top: '+padding_top+'px; height: '+window.innerHeight +'px; width: '+window.innerWidth+'px;'
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
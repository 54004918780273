import axios from "../../mixins/axiosInstanceV2";
import qs from "qs";
import Compressor from "compressorjs";
import { io } from "socket.io-client";

const socket = io("https://crm.radiotrunk.com:5051", {
  transports: ["websocket", "polling"],
  secure: true,
});
socket.on("connection", () => {
  console.log("Connected");
});

const state = () => ({
  headers: [
    { text: "ID", sortable: true, value: "ticket_id" },
    { text: "Comentario", value: "comments" },
    { text: "Sucursal", sortable: true, value: "username" },
    { text: "Cuenta", sortable: true, value: "account" },
    { text: "Supervisor", sortable: true, value: "advisor" },
    { text: "N° de Serie", sortable: true, value: "SN" },
    { text: "Creado por", sortable: true, value: "createdBy" },
    { text: "Fecha Inicio", sortable: true, value: "init_date" },
    { text: "Fecha Termino", sortable: true, value: "end_date" },
    { text: "Estado", sortable: true, value: "status" },
  ],
  tickets: [], //List of tickets deployed in the main table
  loading: true,
  search: "",
  TicketDialog: false, //Set if the Ticket's dialog is showed or not
  TicektInformation: {},
  ThumbnailData: [],
});

const getters = {};

const actions = {
  async getThumbnails({ commit, state }) {
    axios.instance.defaults.headers.common["token"] = localStorage.getItem(
      "token"
    );
    var data = await axios.instance.get(
      "/tickets_media?ticket_id=" + state.TicektInformation.ticket_id
    );
    console.log(data);
    if (data.data.data != "This ticket has no media") {
      commit("setThumbnailsData", data.data.data);
    }
  },
  async changeStatus({ dispatch, state }) {
    await dispatch("Services/getServiceInfo", null, { root: true });
    axios.instance.defaults.headers.common["token"] = localStorage.getItem(
      "token"
    );
    await axios.instance.patch(
      "/tickets/" + state.TicektInformation.ticket_id,
      qs.stringify({
        statuss: "En Revision",
        advisor: localStorage.getItem("name"),
      })
    );
    await dispatch("getAllTickets");
  },
  async getAllTickets({ commit }) {
    socket.on("fetchTickets", async () => {
      axios.instance.defaults.headers.common["token"] = localStorage.getItem(
        "token"
      );
      var data = await axios.instance.get("/tickets");
      if (data.data.data != "No registers detected") {
        commit("setAllTickets", data);
      }
      commit("reloadItemState", false);
    });
    axios.instance.defaults.headers.common["token"] = localStorage.getItem(
      "token"
    );
    var data = await axios.instance.get("/tickets");
    if (data.data.data != "No registers detected") {
      commit("setAllTickets", data);
    }
    commit("reloadItemState", false);
  },
  async openTicketDialog({ commit, dispatch }, value) {
    await commit("openTicketDialog");
    await commit("setTicketInformation", value);
    await commit("ArriveImages/updateArriveImages", [], { root: true });
    await commit("ReturnImages/updateReturnImages", [], { root: true });
    await dispatch("getThumbnails");
    await dispatch("changeStatus");
  },
  async closeTicketDialog({ commit }) {
    commit("closeTicketDialog", []);
  },
  async cancelTicket({ state, dispatch, commit }) {
    dispatch("Services/saveChanges", null, { root: true });
    axios.instance.defaults.headers.common["token"] = localStorage.getItem(
      "token"
    );
    await axios.instance.patch(
      "/tickets/" + state.TicektInformation.ticket_id,
      qs.stringify({
        statuss: "Cancelado",
      })
    );
    commit("closeTicketDialog");
    dispatch("getAllTickets");
  },
  async endTicket({ state, dispatch, commit, rootState }) {
    await dispatch("Services/saveChangesService", null, { root: true });
    axios.instance.defaults.headers.common["token"] = localStorage.getItem(
      "token"
    );
    await axios.instance.patch(
      "/tickets/" + state.TicektInformation.ticket_id,
      qs.stringify({
        statuss: "Resuelto",
      })
    );
    await commit("closeTicketDialog");
    await commit("Loader/showLoader", null, { root: true });
    if (rootState.ArriveImages.arriveImages.length > 0) {
      for (let i = 0; i < rootState.ArriveImages.arriveImages.length; i++) {
        new Compressor(rootState.ArriveImages.arriveImages[i], {
          quality: 0.3,
          success(result) {
            let data = new FormData();
            var image = new File([result], result.name);
            data.append("file", image);
            data.append("service_id", rootState.Services.service_id);
            axios.instance
              .post("/imgArrive", data)
              .then(function() {
                dispatch(
                  "Snackbars/setSnackbars",
                  { text: "Una Imagen se cargo con exito", type: "success" },
                  { root: true }
                );
              })
              .catch(function() {
                dispatch(
                  "Snackbars/setSnackbars",
                  { text: "Uma imagen no se pudo subir", type: "error" },
                  { root: true }
                );
              });
          },
          error() {
            dispatch(
              "Snackbars/setSnackbars",
              { text: "Uma imagen no se pudo subir", type: "error" },
              { root: true }
            );
          },
        });
      }
    }
    if (rootState.ReturnImages.returnImages.length > 0) {
      for (let i = 0; i < rootState.ReturnImages.returnImages.length; i++) {
        new Compressor(rootState.ReturnImages.returnImages[i], {
          quality: 0.3,
          async success(result) {
            let data = new FormData();
            var image = new File([result], result.name);
            data.append("file", image);
            data.append("service_id", rootState.Services.service_id);
            axios.instance
              .post("/imgReturn", data)
              .then(function() {
                dispatch(
                  "Snackbars/setSnackbars",
                  { text: "Una Imagen se cargo con exito", type: "success" },
                  { root: true }
                );
              })
              .catch(function() {
                dispatch(
                  "Snackbars/setSnackbars",
                  { text: "Uma imagen no se pudo subir", type: "error" },
                  { root: true }
                );
              });
          },
          error() {
            dispatch(
              "Snackbars/setSnackbars",
              { text: "Uma imagen no se pudo subir", type: "error" },
              { root: true }
            );
          },
        });
      }
    }
    await commit("Loader/closeLoader", null, { root: true });
    await dispatch("getAllTickets");
  },
};

const mutations = {
  setAllTickets(state, value) {
    state.tickets = value.data.data;
  },
  setThumbnailsData(state, data) {
    state.ThumbnailData = data;
  },
  openTicketDialog(state) {
    state.TicketDialog = true;
  },
  closeTicketDialog(state) {
    state.TicketDialog = false;
  },
  setTicketInformation(state, value) {
    state.TicektInformation = value;
  },
  setSearchterm(state, value) {
    state.search = value;
  },
  reloadItemState(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

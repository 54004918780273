import axios from '../../../mixins/axiosInstanceV2.js';
import qs from 'qs';
const state=()=>({
    dialogValuesSim:{
        carrier: null,
        phoneNumber:null,
        simNumber:null,
        dateInitSim:null,
        menuDateInitSim:null,
        dateEndSim:null,
        menuDateEndSim:null,
        parentAccount:null,
        childAccount:null,
        contractNumber:null
    },
    lastSimAdded:null
})

const getters ={}

const actions ={
    async addSim({commit},value){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        let sim=await axios.instance.post("/sims",qs.stringify({
           carrier:         value.carrier,
           phone_number:    value.phoneNumber,
           child_account:   value.childAccount,
           sim_number:      value.simNumber,
           parent_account:  value.parentAccount,
           date_init:       value.dateInitSim,
           date_end:        value.dateEndSim,
           contract_number: value.contractNumber
        }));
        console.log(sim)
        await commit('updateLastSimAdded',sim.data.data.sim_id)
    }
}

const mutations ={
    async updateLastSimAdded(state,value){
        state.lastSimAdded=value
    },
    updateCarrier(state,value){
        state.dialogValuesSim.carrier=value
    },
    updatePhoneNumber(state,value){
        state.dialogValuesSim.phoneNumber=value
    },
    updateSimNumber(state,value){
        state.dialogValuesSim.simNumber=value
    },
    updateParentAccount(state,value){
        state.dialogValuesSim.parentAccount=value
    },
    updateChildAccount(state,value){
        state.dialogValuesSim.childAccount=value
    },
    updateContractNumber(state,value){
        state.dialogValuesSim.contractNumber=value
    },
    updateDateInitSim(state,value){
        state.dialogValuesSim.dateInitSim=value
    },
    updateDateEndSim(state,value){
        state.dialogValuesSim.dateEndSim=value
    },
    resetDialogValuesSims(state){
        state.dialogValuesSim.carrier=undefined
        state.dialogValuesSim.phoneNumber=undefined
        state.dialogValuesSim.simNumber=undefined
        state.dialogValuesSim.dateInitSim=undefined
        state.dialogValuesSim.dateEndSim=undefined
        state.dialogValuesSim.parentAccount=undefined
        state.dialogValuesSim.childAccount=undefined
        state.dialogValuesSim.contractNumber=undefined
    },
    returnValuesSim(state,value){
        state.dialogValuesSim.carrier=value.carrier
        state.dialogValuesSim.phoneNumber=value.phoneNumber
        state.dialogValuesSim.simNumber=value.simNumber
        state.dialogValuesSim.dateInitSim=value.dateInitSim
        state.dialogValuesSim.dateEndSim=value.dateEndSim
        state.dialogValuesSim.parentAccount=value.parentAccount
        state.dialogValuesSim.childAccount=value.childAccount
        state.dialogValuesSim.contractNumber=value.contractNumber
    }
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
import axios from '../../mixins/axiosInstanceV2.js'
import qs from 'qs'
const state=()=>({
    dropdownMarks:[],
    dropdownMark:{},
    addMarkDialog:false,
    deleteMarkDialog:false,
    dialogValuesMark:{
        name: "",
    },

})

const getters ={}

const actions ={
    async returnValuesMark({dispatch,commit},value){
        dispatch('getDropdownMarks')
        commit('updateDropdownMark',value.dropdownMark)
    },
    async getDropdownMarks({commit})
    {
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        let data = await axios.instance.get("/marks");
        commit('updateDropdownMarks',data.data.data)
    },
    async addMark({commit,state,dispatch}){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.post("/marks",qs.stringify({
            name:state.dialogValuesMark.name.toUpperCase(),
        }));
        dispatch('getDropdownMarks')
        commit('closeAddMarkDialog')
    }
}

const mutations ={
    async updateDropdownMark(state,value){
        state.dropdownMark=value
    },
    async updateDropdownMarks(state,value){
        state.dropdownMarks=value
    },
    async updateName(state,value){
        state.dialogValuesMark.name=value
    },
    async showAddMarkDialog(state){
        state.addMarkDialog=true
    },
    async closeAddMarkDialog(state){
        state.addMarkDialog=false
        state.dialogValuesMark.name=""
    }
    
}


export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}
import axios from '../../mixins/axiosInstanceV2';
import qs from 'qs'
const state = () => ({
    row:[  
        {id:1,estado:'',pregunta:" ¿Estas aplicando una garantia?"},
        {id:2,estado:'',pregunta:"         ¿Se remplazo un radio?"},
        {id:3,estado:'',pregunta:"  ¿Se reviso en el laboratorio?"},
        {id:4,estado:'',pregunta:"  ¿Se realizo soporte en linea?"},
        {id:5,estado:'',pregunta:"¿Se realizo un cambio en linea?"},
    ],//This variable is used for the questions of the tickets table
    service_id:null, //Is the service_id linked with the ticket
    comment:""
})

const getters = {}

const actions = {
    async saveChangesService({state,commit,rootState,dispatch})
    {
        await commit('fillComment')
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.put("/services/"+state.service_id,qs.stringify({
            warranty:state.row[0].estado,
            radio_replacement:state.row[1].estado,
            lab_review:state.row[2].estado,
            online_support:state.row[3].estado,
            online_changes:state.row[4].estado,
            comments:state.comment,
            service_date:new Date(),
            device_id:rootState.Tickets.TicektInformation.device_id
        }));
        await dispatch('Tickets/closeTicketDialog',null,{root:true})
    },
    async getServiceInfo({commit,dispatch,rootState})
    {
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        let res=await axios.instance.get("/services?ticket_id="+rootState.Tickets.TicektInformation.ticket_id+"&device_id="+rootState.Tickets.TicektInformation.device_id)
        await commit('setServiceInfo',res);
        await dispatch('Spares/getSpares',null,{root:true})
    },
}

const mutations = {

    fillComment(state){
        if(state.comment==="")
        {
            state.comment="."
        }
    },
    setServiceInfo(state,value){
        state.row[0].estado=""+value.data.data[0].warranty+""
        state.row[1].estado=""+value.data.data[0].radio_replacement+""
        state.row[2].estado=""+value.data.data[0].lab_review+""
        state.row[3].estado=""+value.data.data[0].online_support+""
        state.row[4].estado=""+value.data.data[0].online_changes+""
        if(value.data.data[0].comments===".")
        {
            state.comment=""
        }else{
            state.comment=value.data.data[0].comments
        }
        state.service_id=value.data.data[0].service_id

    },
    changeChooser(state,value)
    {
        state.row[value.id-1].estado=value.value
    },
    updateComment(state,value)
    {
        state.comment=value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import axios from '../../mixins/axiosInstance.js';

const state = () => ({
    allNotifications: [],
    notificationsCount: 0,
    search: ''
})

const getters = {}

const actions = {
    async getCount({ commit }) {
        axios.instance.defaults.headers.common['token'] = localStorage.getItem('token');
        axios.instance.get('/notifications?count=true')
            .then(function (response) {
                commit('setCount', response.data.data[0].count)
            }).catch(function (error) {
                console.log(error)
            })
            commit('setCount', 0)
    },
    async getAllNotifications({ commit }) {
        axios.instance.defaults.headers.common['token'] = localStorage.getItem('token');
        axios.instance.get('/notifications')
            .then(function (response) {
                commit('setAllNotifications', response.data.data);
            })
            commit('setAllNotifications', []);
    }
}

const mutations = {
    setCount(state, count) {
        state.notificationsCount = count;
    },
    setAllNotifications(state, notifications) {
        state.allNotifications = notifications
    },
    setHeadNotifications(state, notifications) {
        state.headNotifications = notifications;
    },
    setSearchTerm(state, searchTerm){
        state.search = searchTerm
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
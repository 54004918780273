import axios from '../../mixins/axiosInstanceV2.js'
import qs from 'qs';
import Compressor from 'compressorjs';
const state=()=>({
    headers: [
        {
            text: "ID",
            sortable: true,
            value: "ticket_id"
        },
        {
            text:"Comentario",
            value:"comments"
        },
        {
            text: "Sucursal",
            sortable: true,
            value: "username"
        },
        {
            text: "Cuenta",
            sortable: true,
            value: "account"
        },
        {
            text: "Supervisor",
            sortable:true,
            value: "advisor"
        },
        {
            text: "N° de Serie",
            sortable:true,
            value: "SN"
        },
        {
            text: "Fecha inicio",
            sortable: true,
            value: "init_date"
        },
        {
            text: "Fecha de termino",
            sortable: true,
            value: "end_date"
        },
        {
            text: "Estado",
            sortable:true,
            value: "status"
        }
    ],// This headers are used for the tickets table
    row:[  
        {id:0,estado:'',pregunta:" ¿Estas aplicando una garantia?"},
        {id:1,estado:'',pregunta:"         ¿Se remplazo un radio?"},
        {id:2,estado:'',pregunta:"  ¿Se reviso en el laboratorio?"},
        {id:3,estado:'',pregunta:"  ¿Se realizo soporte en linea?"},
        {id:4,estado:'',pregunta:"¿Se realizo un cambio en linea?"},
    ],//This variable is used for the questions of the tickets table 
    tickets:[], //List of tickets deployed in the main table
    loading:true,
    search:"",
    TicketDialog: false, //Set if the Ticket's dialog is showed or not
    Info: {ticket_id:null},
    service_id:null, //Is the service_id linked with the ticket
    formulario:
    {
        Comentario:"",
        ImagenLlegada:[],
        ImagenSalida:[],
    },
    Spares:{
        SparesSelected:[],
        SpareShow:"",
        SpareVal:"",
        SN:'',
        SparesList:[],
    },
    ThumbnailData: []

})
const getters ={
    Tickets: state => {
        return state.tickets
    }
}
const actions ={
    async changeStatus({dispatch,state})
    {
        await dispatch('getServiceInfo')
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.patch("/tickets/"+state.Info.ticket_id,qs.stringify({
            statuss:"En Revision",
            advisor: localStorage.getItem("name")
        }));
        dispatch('getAllTickets')
    },
    async saveChanges({state,commit})
    {
        commit('fillComment')
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.put("/services/"+state.service_id,qs.stringify({
            warranty:state.row[0].estado,
            radio_replacement:state.row[1].estado,
            lab_review:state.row[2].estado,
            online_support:state.row[3].estado,
            online_changes:state.row[4].estado,
            comments:state.formulario.Comentario,
            service_date:new Date(),
            device_id:state.Info.device_id
        }));
    },
    async getAllTickets({commit})
    {
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        var data = await axios.instance.get("/tickets");
        if(data.data.data != 'No registers detected'){
            commit('setAllTickets',data)
        }
        commit('reloadItemState', false)
    },
    async getServiceInfo({commit,state}){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        let res=await axios.instance.get("/services?ticket_id="+state.Info.ticket_id+'&device_id='+state.Info.device_id);
        commit('setServiceInfo',res)
    },
    async getSpares({commit}){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        let res=await axios.instance.get("/spares");
        let Spares=[]
        if(res.data.data!="No registers detected")
        {
            for(let i=0;i<res.data.data.length;i++)
            {
                Spares.push({text:res.data.data[i].spare_name,value:{val:res.data.data[i].spare_id,show:res.data.data[i].spare_name}})
            }
        }
        commit('setSpares',Spares)
    },
    async getThumbnails({commit,state})
    {
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        var data = await axios.instance.get("/tickets_media?ticket_id="+state.Info.ticket_id);
        if(data.data.data != 'This ticket has no media'){
            commit('setThumbnailsData', data.data.data)
        }
    },
    async cancelTicket({state,dispatch,commit})
    {
        dispatch('saveChanges')
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.patch("/tickets/"+state.Info.ticket_id,qs.stringify({
            statuss:"Cancelado",
        }));
        commit('openTicketDialog',{enable:false})
        dispatch('getAllTickets')
    },
    async endTicket({state,dispatch,commit})
    {
        
        dispatch('saveChanges')
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
        await axios.instance.patch("/tickets/"+state.Info.ticket_id,qs.stringify({
            statuss:"Resuelto",
        }));
        if(state.formulario.ImagenLlegada.length>0)
        {
          for(let i=0;i<state.formulario.ImagenLlegada.length;i++)
          {
            new Compressor(state.formulario.ImagenLlegada[i], {
              quality: 0.3,
              success(result) {
                let data = new FormData();
                var image = new File([result], result.name);
                data.append('file', image)
                data.append('service_id', state.service_id)
                axios.instance.post("/imgArrive", data)
                .then(function(result){
                console.log(result)
                }).catch(function(err){
                  console.log(err);
                })
              },
              error(err) {
                console.log(err.message);
              },
            });
          }
        }
        if(state.formulario.ImagenSalida.length>0)
        {
          for(let i=0;i<state.formulario.ImagenSalida.length;i++)
          {
            new Compressor(state.formulario.ImagenSalida[i], {
              quality: 0.3,
              async success(result) {
                let data = new FormData();
                var image = new File([result], result.name);
                console.log(image)
                data.append('file', image)
                data.append('service_id', state.service_id)
                axios.instance.post("/imgReturn", data)
                .then(function(result){
                console.log(result)
                }).catch(function(err){
                  console.log(err);
                })
              },
              error(err) {
                console.log(err.message);
              },
            });
          }
        }
        for(let i=0;i<state.Spares.SparesSelected.length;i++)
        {
            await axios.instance.post("/spares",qs.stringify({
                spare_id:state.Spares.SparesSelected[i].ID,
                spare_sn:state.Spares.SparesSelected[i].SN,
                service_id:state.service_id,
            }));
            
        }
        commit('openTicketDialog',{enable:false})
        dispatch('getAllTickets')
    }
}

const mutations ={
    setSearchterm(state, payload){
        state.search = payload
    },
    setThumbnailsData(state, data){
        state.ThumbnailData = data
    },
    removeSpare(state,val){
        state.Spares.SparesSelected=state.Spares.SparesSelected.filter(function(value){
            return  value.SN!=val.SN
        })
    },
    reloadItemState(state, value){
        state.loading = value
    },
    fillComment(state)
    {
        if(state.formulario.Comentario==="")
        {
            state.formulario.Comentario="."
        }
    },
    addSpareToList(state){
        if(state.Spares.SpareVal!=""&&state.Spares.SpareShow)
        {
            state.Spares.SparesSelected.push({ID:state.Spares.SpareVal,Spare:state.Spares.SpareShow,SN:state.Spares.SN})
        }else{
            alert('Seleccione una refaccion')
        }
        
        state.Spares.SparesList=[]
        state.Spares.SN=""
    },
    changeSpare(state,value){
        state.Spares.SpareShow=value.show
        state.Spares.SpareVal=value.val
    },
    changeSpareSN(state,value){
        state.Spares.SN=value
    },
    setServiceInfo(state,res){
        state.row[0].estado=""+res.data.data[0].warranty+""
        state.row[1].estado=""+res.data.data[0].radio_replacement+""
        state.row[2].estado=""+res.data.data[0].lab_review+""
        state.row[3].estado=""+res.data.data[0].online_support+""
        state.row[4].estado=""+res.data.data[0].online_changes+""
        if(res.data.data[0].comments===".")
        {
            state.formulario.Comentario=""
        }else{
            state.formulario.Comentario=res.data.data[0].comments
        }
        state.service_id=res.data.data[0].service_id

    },
    setAllTickets(state,data) {
        
        state.tickets=data.data.data;
    },
    setSpares(state,res){

        state.Spares.SparesList=res
    },
    openTicketDialog(state,value)
    {
        
        if(value.objeto!=null&&value.objeto!=undefined)
        {
            state.Info=value.objeto
        }
        state.TicketDialog=value.enable
        if(state.TicketDialog===false)
        {
            state.formulario.ImagenLlegada=[]
            state.formulario.ImagenSalida=[]
            state.Spares.SpareShow=""
            state.Spares.SpareVal=""
            state.Spares.SN=''
            state.Spares.SparesList=[]
            state.Spares.SparesSelected=[]
        }
    },
    changeChooser(state,values)
    {
        
        state.row[values.id].estado=values.value
    },
    updateComentario(state,comment)
    {
        state.formulario.Comentario=comment
    },
    setImageArrive(state,value)
    {
        state.formulario.ImagenLlegada=value
    },
    setImageReturn(state,value)
    {
        state.formulario.ImagenSalida=value
    },
}
export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
  }
import axios from '../../../mixins/axiosInstanceV2.js'
import qs from 'qs'
const state=()=>({
    dialogValuesSim:{
        simID:null,
        carrier: null,
        phoneNumber:null,
        simNumber:null,
        dateInitSim:null,
        menuDateInitSim:null,
        dateEndSim:null,
        menuDateEndSim:null,
        parentAccount:null,
        childAccount:null,
        contractNumber:null
    },
})

const getters ={}

const actions ={
    async updateSim({state}){
        await axios.instance.put("/sims/"+state.dialogValuesSim.simID ,qs.stringify({
            
            carrier:state.dialogValuesSim.carrier,
            phone_number:state.dialogValuesSim.phoneNumber,
            child_account:state.dialogValuesSim.childAccount,
            sim_number:state.dialogValuesSim.simNumber,
            parent_account:state.dialogValuesSim.parentAccount,
            contract_number:state.dialogValuesSim.contractNumber,
            date_init:state.dialogValuesSim.dateInitSim,
            date_end:state.dialogValuesSim.dateEndSim
        }));
    }
}

const mutations ={
    updateCarrier(state,value){
        state.dialogValuesSim.carrier=value
    },
    updatePhoneNumber(state,value){
        state.dialogValuesSim.phoneNumber=value
    },
    updateSimNumber(state,value){
        state.dialogValuesSim.simNumber=value
    },
    updateParentAccount(state,value){
        state.dialogValuesSim.parentAccount=value
    },
    updateChildAccount(state,value){
        state.dialogValuesSim.childAccount=value
    },
    updateContractNumber(state,value){
        state.dialogValuesSim.contractNumber=value
    },
    updateDateInitSim(state,value){
        state.dialogValuesSim.dateInitSim=value
    },
    updateDateEndSim(state,value){
        state.dialogValuesSim.dateEndSim=value
    },
    updateSimID(state,value){
        state.dialogValuesSim.simID=value
    }
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}